// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

@import "../node_modules/bootstrap/scss/functions"; // Required
$cyan: #0dcaf0;
$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$red: #dc3545;
$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $cyan !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange: #fd7e14;
$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow: #ffc107;
$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$primary: $orange;
$secondary: $orange-300;
$light: $orange-100;
$dark: $orange-900;

$enable-cssgrid: true;
$enable-gradients: true;
$enable-shadows: true;

$list-group-border-color:  $orange;
$list-group-border-width:  .1rem;
$list-group-border-radius: .5rem;

@import "../node_modules/bootstrap/scss/bootstrap";

.dark-theme {
  $body-color: #eeeeee;
  $body-bg: $orange-900;
  
  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};
}

//
// Custom styles
//
.container {
  padding: 0.5rem;
}

.header {
  @include gradient-x-three-colors($red-600, $orange-500, 0%, $yellow-400);
}

a {
  text-decoration: none;
}

// Style Bootstrap icons
.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.feature {
  color: $orange;
}

.fw-bold {
  color: $orange;
}

.text-red {
  color: $red-600 !important;
}

.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #fff;
  border-radius: .4rem;
}

.icon-link {
  display: inline-flex;
  align-items: center;
}
.icon-link > .bi {
  margin-top: .125rem;
  margin-left: .125rem;
  transition: transform .25s ease-in-out;
  fill: currentColor;
}
.icon-link:hover > .bi {
  transform: translate(.25rem);
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: .75rem;
}

.rounded-4 { border-radius: .5rem; }
.rounded-5 { border-radius: 1rem; }

.navbar-brand {
  font-weight: bold;
}

.veiledmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}
